import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
  Button, Typography,
} from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import desktopGeneric from '../../../../assets/images/desktop-generic.svg';
import Trademarked from '../../../../components/atoms/Trademarked';

const useStyles = makeStyles(theme => ({
  fontWeight700: {
    fontWeight: 700
  },
  image: {
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      order: -1
    }
  }
}));

const MacOS = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  
  return (
    <Grid container spacing={isMd ? 4 : 2}>
      <Grid
        item
        container
        alignItems="center"
        xs={12}
        sm={6}
        data-aos="fade-up"
      >
        <SectionHeader
          title={
            <span>
              <Typography component="span" variant="h2" color="secondary" className={classes.fontWeight700}>
                Desktop App
              </Typography>
            </span>
          }
          subtitle={<><Trademarked>Formigio</Trademarked> Personal for Mac and Windows is on it's way</>}
          ctaGroup={[
            <Button type="button" color="primary" variant="contained" disabled>
              Coming Soon
            </Button>
          ]}
          align={isMd ? 'left' : 'center'}
          disableGutter
          titleVariant="h2"
        />
      </Grid>
      <Grid item xs={12} sm={6} data-aos="fade-up" className={classes.image}>
        <Grid container alignItems="flex-start" justify="center">
          <Image
            src={desktopGeneric}
            alt="..."
            lazy={false}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MacOS;

import React from 'react';
import { makeStyles  } from '@material-ui/core/styles';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { HeroShaped } from 'components/organisms';
import {Button} from '@material-ui/core';
import Trademarked from '../../../../components/atoms/Trademarked';

const useStyles = makeStyles(theme => ({
  cover: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  }
}));

const Hero = () => {
  const classes = useStyles();
  return (
    <div>
      <HeroShaped
        leftSide={
          <SectionHeader
            title="Try before you buy!"
            subtitle={<>Become a <Trademarked>Formigio</Trademarked> member for free!</>}
            align="left"
            disableGutter
            data-aos="fade-up"
            titleVariant="h1"
            titleProps={{
              color: 'primary'
            }}
            ctaGroup={[
              <Button variant="contained" color="secondary" size="large" component="a" href="https://app.formigio.com/register">
                Sign Up Now
              </Button>,
              <Button variant="outlined" size="large" component="a" href="https://app.formigio.com/">
                Log In
              </Button>
            ]}

          />
        }
        rightSide={
          <div className={classes.cover}>
            <Image
              src="https://assets.maccarianagency.com/the-front/illustrations/relax-working.svg"
              alt="..."
              className={classes.coverImg}
              data-aos="fade-up"
              lazy={false}
            />
          </div>
        }
      />
    </div>
  );
};

export default Hero;

import React from 'react';
import { Section, SectionAlternate } from 'components/organisms';
import { Hero, IOS, Web, Android, MacOS, Windows } from './components';
import {Helmet} from 'react-helmet';

const Download = () => (
  <div>
    <Helmet>
      <title>Formigio (tm) - Access the applications and use them to plan your day and track your goals</title>
    </Helmet>
    <Hero />
    <SectionAlternate>
      <Web />
    </SectionAlternate>
    <Section narrow>
      <IOS />
    </Section>
    <Section narrow>
      <Android />
    </Section>
    <SectionAlternate>
      <MacOS />
    </SectionAlternate>
    {/*<Section >*/}
    {/*  <Windows />*/}
    {/*</Section>*/}
  </div>
);

export default Download

import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import appButton from 'assets/images/app_button.png';
import Trademarked from '../../../../components/atoms/Trademarked';

const useStyles = makeStyles(theme => ({
  fontWeight700: {
    fontWeight: 700
  },
  image: {
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      order: -1
    }
  }
}));

const Android = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  
  return (
    <Grid container spacing={isMd ? 4 : 2}>
      <Grid item xs={12} sm={6} data-aos="fade-up" className={classes.image}>
        <Grid container alignItems="flex-start" justify="center">
          <Image
            src={appButton}
            alt="App icon"
            lazy={true}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        xs={12}
        sm={6}
        data-aos="fade-up"
      >
        <SectionHeader
          title={
            <span>
              Android - {' '}
              <Typography component="span" variant="h2" color="primary" className={classes.fontWeight700}>
                Phone and Tablet
              </Typography>
            </span>
          }
          subtitle={<><Trademarked>Formigio</Trademarked> Companion for Android for those that want their trackers in their pocket</>}
          ctaGroup={[
            <Button type="button" color="primary" variant="contained" disabled>
              Beta sign up coming soon
            </Button>
          ]}
          align={isMd ? 'left' : 'center'}
          disableGutter
          titleVariant="h2"
        />
      </Grid>
    </Grid>
  );
};

export default Android;
